import { body, paragraph, Avatar } from "@dalmatech/ui"
import Image from "next/image"
import React from "react"
import type { ReactNode } from "react"

import * as styles from "./info-card.styled"

type InfoCardProps = {
  title?: ReactNode
  description: ReactNode
}

export const InfoCard = ({ description, title }: InfoCardProps) => {
  return (
    <div className={styles.root}>
      <Avatar size="sm">
        <Image
          src="/img/salome-avatar.png"
          alt="woman avatar"
          width={32}
          height={32}
          className={styles.avatar}
          loading="eager"
        />
      </Avatar>
      <div>
        <p className={body({ size: "sm" })}>{title}</p>
        <p className={paragraph({ size: "sm" })}>{description}</p>
      </div>
    </div>
  )
}
