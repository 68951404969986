import { calendly } from "@/shared/utils/links"
import { Dialog, DialogContent, DialogTrigger } from "@dalmatech/ui"
import { PropsWithChildren, useState } from "react"

import * as styles from "./booking-modal.styled"

export const BookingModal = ({ children }: PropsWithChildren) => {
  const [isLoading, setLoading] = useState(true)

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className={styles.content}>
        {isLoading ? <div className={styles.loading} /> : null}
        <iframe
          onLoad={() => setLoading(false)}
          width="100%"
          height="100%"
          title="Calendly Scheduling Page"
          className={styles.iframe}
          src={calendly}
        />
      </DialogContent>
    </Dialog>
  )
}
