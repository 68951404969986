"use client"

import { i18n } from "@/modules/App/components/i18n/i18n.client"
import { de, enGB } from "date-fns/locale"
import React from "react"
import { DayPicker, Modifiers } from "react-day-picker"
import "react-day-picker/style.css"

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  mode: "single"
  required?: false | undefined
  onSelect?: (
    selected: Date | undefined,
    triggerDate: Date,
    modifiers: Modifiers,
    e: React.MouseEvent | React.KeyboardEvent
  ) => void
  className?: string
  showOutsideDays?: boolean
  disabled?: { before: Date; after: Date }
}

export const Calendar = React.forwardRef<React.ElementRef<"div">, CalendarProps>(
  ({ showOutsideDays = false, onSelect, disabled, selected, ...props }, ref) => {
    const language = i18n.language === "de" ? "de" : "enGB"

    return (
      <div ref={ref}>
        <style jsx>{styles}</style>
        <DayPicker
          showOutsideDays={showOutsideDays}
          datepicker-autoselect-today
          onSelect={onSelect}
          selected={selected}
          disabled={{
            before: disabled?.before || new Date(),
            after: disabled?.after,
          }}
          defaultMonth={selected || disabled?.before || new Date()}
          lang={language}
          locale={language === "de" ? de : enGB}
          modifiersClassNames={{
            today: "my-today",
            selected: "my-selected",
            disabled: "my-disabled",
            day: "rdp-day_button",
            nav_button_next: "rdp-nav",
            month_caption: "rdp-month_caption",
          }}
          weekStartsOn={1}
          {...props}
        />
      </div>
    )
  }
)

Calendar.displayName = "Calendar"

//have to use some classic css here because of the way react-day-picker is built

const styles = `
  .my-today {
    position: relative;
  }

  .my-today::after {
    content: "";
    position: absolute;
    bottom: -0px;
    left: 50%;
    transform: translateX(-20%);
    width: 5px;
    height: 5px;
    background-color: #4E68F6;
    border-radius: 50%;
  }

  .my-selected:not([disabled]) {
    font-weight: bold; 
    background: #4E68F6 !important;
    border-radius: 50%;
    background-size: cover;
    color: white;
  }
  .my-selected:not([disabled]):hover {
    cursor: pointer;
    background: initial;
    color: initial;
  }

  .my-disabled {
    color: gray;
    text-decoration: line-through;
  }

  .my-disabled:hover {
    cursor: not-allowed;
  }

  .rdp-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .rdp-month_caption {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rdp-day_button.my-selected:hover {
    background: initial;
  }

  .rdp-day_button:not(.my-disabled):not(.my-selected) {
    transition: background 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  }

  .rdp-day_button:not([disabled]):not(.my-selected):hover {
    background: #EBEAEB;
    border-radius: 50%;
  }

  @media (hover: none) {
    .rdp-day_button:not([disabled]):not(.my-selected):hover {
      background: #4E68F6;
      color: white;
    }
  }
`
