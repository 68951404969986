import { config } from "@/shared/utils/config"
import { calendly } from "@/shared/utils/links"
import { Drawer, DrawerTrigger, DrawerContent } from "@dalmatech/ui"
import { useState, PropsWithChildren } from "react"

import * as styles from "./booking-drawer.styled"

export const BookingDrawer = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)

  return (
    <Drawer open={isOpen} onOpenChange={setOpen} onClose={() => setOpen(false)}>
      <DrawerTrigger>{children}</DrawerTrigger>
      <DrawerContent onClose={() => setOpen(false)} className={styles.content}>
        {isLoading ? <div className={styles.loading} /> : null}
        <iframe
          onLoad={() => setLoading(false)}
          width="100%"
          height="100%"
          title="Calendly Scheduling Page"
          className={styles.iframe}
          src={calendly}
        />
      </DrawerContent>
    </Drawer>
  )
}
