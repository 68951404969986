"use client"

import { useClientTranslation } from "@/modules/App/components/i18n/i18n.client"
import { isPetTooOld, isPetTooYoung } from "@/modules/Pet/helpers/helpers"
import { useSyncBack } from "@/modules/Quote/hooks/use-sync-back"
import { Calendar } from "@/shared/components/Calendar/calendar"
import { useMediaQuery } from "@/shared/hooks/use-media-query"
import { Pet } from "@/shared/types/global-store"
import { IS_DEFFERED_SUBSCRIPTION_ACTIVE } from "@/shared/utils/flag-features"
import { useGlobalState } from "@/store/global-store"
import { matchHasMultiplePets } from "@/store/helpers"
import { SubscriptionFlowContext } from "@/store/machine"
import { Button, Popover, PopoverContent, PopoverTrigger } from "@dalmatech/ui"
import { addMonths, addYears, format, isFuture } from "date-fns"
import { de, enGB } from "date-fns/locale"
import { CalendarIcon, X } from "lucide-react"
import { useState } from "react"
import { Control, Controller } from "react-hook-form"
import { Drawer as DrawerPrimitives } from "vaul"

import { InfoCard } from "../InfoCard/info-card"
import * as styles from "./calendar-section.styled"

type CalendarSectionProps = {
  control: Control<PrepackagesFormValues>
  currentPet: Pet | null
  startDate: Date | string | undefined
  pets: Pet[]
  refetch: () => Promise<any>
}
type PrepackagesFormValues = {
  prepackage: "small" | "medium" | "large"
  start_date: Date
}

matchHasMultiplePets

export const getMinFirstDate = (
  petsOrDate: Pet[] | Date,
  context?: SubscriptionFlowContext
): Date => {
  if (petsOrDate instanceof Date) {
    return addMonths(petsOrDate, 2)
  }

  if (!IS_DEFFERED_SUBSCRIPTION_ACTIVE) {
    return new Date()
  }
  const today = new Date()
  let minDate = today

  if (context?.meta?.start_date) {
    return new Date(context.meta.start_date)
  }

  petsOrDate.map((pet) => {
    if (pet.birthday) {
      const birthday = new Date(pet.birthday)
      const eligibleDate = addMonths(birthday, 2)

      if (eligibleDate > minDate) {
        minDate = eligibleDate
      }
    }
  })

  return minDate
}

const getMaxFirstDate = (currentPet?: Pet | null) => {
  if (!IS_DEFFERED_SUBSCRIPTION_ACTIVE) {
    return new Date()
  }
  const maxDateBasedOnPetAge = currentPet
    ? addYears(new Date(currentPet.birthday), 9)
    : new Date(2070, 9, 1)

  const maxDateThreeMonthsFromNow = addMonths(new Date(), 3)
  return new Date(Math.min(maxDateBasedOnPetAge.getTime(), maxDateThreeMonthsFromNow.getTime()))
}

const checkYoungAndOldPets = (pets: Pet[]): boolean => {
  const today = new Date()

  const hasYoungPet = pets.some((pet) => {
    if (!pet.birthday) return false
    const birthday = new Date(pet.birthday)
    return isPetTooYoung(birthday, today)
  })

  const hasOldPet = pets.some((pet) => {
    if (!pet.birthday) return false
    const birthday = new Date(pet.birthday)
    return isPetTooOld(birthday, today)
  })

  return hasYoungPet && hasOldPet
}

const getAlertMessage = (
  pet: Pet,
  pets: Pet[],
  t: (key: string, params?: Record<string, any>) => string,
  locale: Locale
): string => {
  if (!pet.birthday) return t("calendar-alert")

  const birthday = new Date(pet.birthday)
  const today = new Date()
  const ageInMonths =
    (today.getFullYear() - birthday.getFullYear()) * 12 + (today.getMonth() - birthday.getMonth())

  if (pets && pets.length > 0 && checkYoungAndOldPets(pets)) {
    return t("calendar-alert-old-and-young")
  }
  if (isPetTooYoung(birthday, today)) {
    const startDate = format(getMinFirstDate([pet]), "PPP", { locale })
    return t("calendar-alert-too-young", { pet_name: pet.name, start_date: startDate })
  }

  if (ageInMonths > 100) {
    return t("calendar-alert-too-old", { pet_name: pet.name })
  }

  return t("calendar-alert")
}

export const CalendarSection = ({
  control,
  currentPet,
  startDate,
  pets,
  refetch,
}: CalendarSectionProps) => {
  const { t, i18n } = useClientTranslation("prepackages-page")
  const {
    helpers: { validPets },
    context,
    send,
  } = useGlobalState()

  const language = i18n.language === "de" ? "de" : "enGB"
  const locale = language === "de" ? de : enGB
  const { mutateAsync: syncBack } = useSyncBack()

  const isViewportBiggerThanMobile = useMediaQuery("(min-width: 768px)")
  const [open, setOpen] = useState(false)
  const minDate = getMinFirstDate(validPets)
  const alertMessage = currentPet
    ? getAlertMessage(
        currentPet,
        pets,
        t as (key: string, params?: Record<string, any>) => string,
        locale
      )
    : t("calendar-alert")

  const handleDateSelect = async (date: Date | undefined) => {
    if (date) {
      localStorage.setItem("selectedStartDate", date.toISOString())
      try {
        const formattedStartDate = format(date, "yyyy-MM-dd")
        const { context } = send({
          type: "UPDATE_PRICING_PLAN_WITHOUT_REDIRECT",
          input: {
            start_date: formattedStartDate,
            subscription_mode: isFuture(new Date(formattedStartDate)) ? "DEFERRED" : "ASAP",
          },
        })
        send({
          type: "ADD_START_DATE",
          input: {
            startDate: formattedStartDate,
          },
        })
        const updatedContext = {
          ...context,
          meta: {
            ...context.meta,
            start_date: formattedStartDate,
            subscription_mode: isFuture(new Date(formattedStartDate))
              ? "DEFERRED"
              : ("ASAP" as "DEFERRED" | "ASAP" | undefined),
          },
        }
        await syncBack(updatedContext, {
          onError: () => {
            console.error("SYNC BACK ERROR")
          },
        })
        const updatedQuote = await syncBack(
          {
            ...updatedContext,
            meta: {
              ...updatedContext.meta,
              subscription_mode: isFuture(new Date(formattedStartDate)) ? "DEFERRED" : "ASAP",
            },
            pets: updatedContext.pets.map((pet) => ({
              ...pet,
            })),
          },
          {
            onError: () => {
              console.error("SYNC BACK ERROR")
            },
          }
        )
        if (Array.isArray(updatedQuote)) {
          send({
            type: "UPDATE_MULTIPLE_PETS_QUOTES",
            pets: updatedQuote,
          })
        } else {
          console.error("Failed to fetch updated prices. Please try again.")
        }
      } catch (error) {
        console.error("Error updating prices:", error)
      }
      await refetch()
    }
  }

  return (
    <div className={styles.startDateSection}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t("start-date.title")}</h2>
        <div className={styles.description}>{t("start-date.description")}</div>
      </div>
      <div className={styles.goodToKnowWrapper}>
        <div className={styles.pickADateWrapper}>
          {isViewportBiggerThanMobile ? (
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant={null}
                  className={`${styles.dayPickerButton} ${
                    !startDate ? "text-muted-foreground" : ""
                  }`}
                >
                  <CalendarIcon className={styles.calendarIcon} />
                  {startDate ? (
                    format(new Date(startDate), "PPP", { locale: locale })
                  ) : (
                    <span>{t("start-date.pick-a-date")}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className={styles.calendarModal}>
                <div className={styles.calendarWrapper}>
                  <Controller
                    control={control}
                    name="start_date"
                    render={({ field }) => (
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={async (date: Date | undefined) => {
                          field.onChange(date)
                          setOpen(false)
                          await handleDateSelect(date)
                        }}
                        locale={locale}
                        // @ts-ignore
                        disabled={{
                          before: minDate,
                          after: getMaxFirstDate(currentPet),
                        }}
                        {...field}
                      />
                    )}
                  />
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            <DrawerPrimitives.Root onOpenChange={setOpen} open={open}>
              <DrawerPrimitives.Trigger asChild>
                <Button
                  variant={null}
                  className={`${styles.dayPickerButton} ${
                    !startDate ? "text-muted-foreground" : ""
                  }`}
                >
                  <CalendarIcon className={styles.calendarIcon} />
                  {startDate ? (
                    format(new Date(startDate), "PPP", { locale: locale })
                  ) : (
                    <span>{t("start-date.pick-a-date")}</span>
                  )}
                </Button>
              </DrawerPrimitives.Trigger>
              <DrawerPrimitives.Overlay />
              <DrawerPrimitives.Portal>
                <DrawerPrimitives.Content className={styles.drawerContent}>
                  <div className={styles.drawerHeader}>
                    <p className={styles.drawerTitle}>{t("start-date.pick-a-date")}</p>
                    <DrawerPrimitives.Close asChild className={styles.closeIcon}>
                      <X />
                    </DrawerPrimitives.Close>
                  </div>
                  <div className={styles.calendarWrapper}>
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field }) => (
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={async (date: Date | undefined) => {
                            field.onChange(date)
                            setOpen(false)
                            await handleDateSelect(date)
                          }}
                          locale={locale}
                          // @ts-ignore
                          disabled={{
                            before: minDate,
                            after: getMaxFirstDate(currentPet),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </DrawerPrimitives.Content>
              </DrawerPrimitives.Portal>
            </DrawerPrimitives.Root>
          )}
        </div>

        <InfoCard description={alertMessage} />
      </div>
    </div>
  )
}
