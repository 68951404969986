export const calendly = "https://calendly.com/dalma-kennenlernen/15-min"

export const termsAndConditions = {
  name: "Terms & conditions",
  description: "IPID PDF - 211 Ko",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/Terms_and_conditions_Dalma_de.pdf",
}

export const IPID = {
  name: "IPID",
  description: "IPID PDF - 178 Ko",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/IPID_Dalma_de.pdf",
} as const

export const privacyAndCookiesPolicies = {
  name: "Policies Dalma",
  description: "IPID PDF - 178 Ko",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/Policies_Dalma_de.pdf",
}

export const garanteesTable = {
  name: "Garantees Table Dalma",
  description: "Garantees PDF - 4.8 Ko",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/Tabelle_der_garantien_Dalma_de.pdf",
}

export const informationNotice = {
  name: "Information Notice Dalma",
  description: "Garantees PDF - 3.5 Mo",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/NI_Dalma_de.pdf",
}

export const Erstinformationen = {
  name: "Erstinformationen Dalma",
  description: "Erstinformationen PDF - 14.9Mo",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/Erstinformation_Dalma_de.pdf",
}

export const informationSheet = {
  name: "information-sheet",
  description: "FIC PDF - 131 Ko",
  link: "https://editique.de.dalma.tech/public/dalma-de/latest/FIC_Dalma_de.pdf",
} as const
