import { cn, paragraph, title as titleStyle } from "@dalmatech/ui"

const wrapper = cn("flex flex-col items-center gap-1")
const title = cn(titleStyle({ size: "lg", className: "text-center" }))
const description = cn(paragraph({ size: "sm", className: "text-neutral-700 text-center" }))
const alertCard = cn("max-w-[27rem] mx-auto -mt-6 md:-mt-8")
const startDateSection = cn("flex w-full flex-col gap-6 lg:gap-8 max-w-[34.125rem] mx-auto")
const goodToKnowSection = cn("flex w-full flex-col gap-6 lg:gap-8 max-w-[34.125rem] mx-auto")
const goodToKnowWrapper = cn("flex w-full flex-col gap-2")
const pickADateWrapper = cn(
  "flex h-12 w-full cursor-text items-center justify-start gap-3 rounded-xl bg-white px-4 py-3.5 font-sans text-sm font-medium -tracking-medium text-black outline-none ring-1 ring-neutral-200 transition-[color,box-shadow] focus-within:ring-4 focus-within:ring-blurple-100 focus-within:ring-offset-2 focus-within:ring-offset-blurple-500 hover:ring-4 hover:ring-neutral-100 hover:ring-offset-2 hover:ring-offset-neutral-300 focus-within:hover:ring-4 focus-within:hover:ring-blurple-100 focus-within:hover:ring-offset-2 focus-within:hover:ring-offset-blurple-500 disabled:cursor-not-allowed"
)
const dayPickerButton = cn("w-[280px] justify-start text-left font-normal")
const calendarIcon = cn("mr-2 h-4 w-4")
const calendarModal = cn(
  "p-auto position:absolute flex w-[25rem] translate-x-32 items-center justify-center"
)
const calendarWrapper = cn("flex w-full items-center justify-center")
const drawerContent = cn("fixed inset-0 bg-white z-50 p-4 ")
const drawerHeader = cn("flex justify-between items-center border-b pb-2 mb-4")
const drawerTitle = cn("text-lg font-medium")
const closeIcon = cn("text-neutral-500 hover:text-neutral-800 cursor-pointer")

export {
  alertCard,
  calendarIcon,
  calendarModal,
  calendarWrapper,
  closeIcon,
  dayPickerButton,
  description,
  drawerContent,
  drawerHeader,
  drawerTitle,
  goodToKnowSection,
  goodToKnowWrapper,
  pickADateWrapper,
  startDateSection,
  title,
  wrapper,
}
