import { BookingDrawer } from "@/shared/components/Booking/BookingModal/booking-drawer"
import { BookingModal } from "@/shared/components/Booking/BookingModal/booking-modal"
import { useMediaQuery } from "@/shared/hooks/use-media-query"
import { PropsWithChildren } from "react"

import * as styles from "./booking.styled"

export const BookingComp = ({
  children,
  onClick,
}: PropsWithChildren<{
  onClick?: () => void
}>) => {
  const isViewportBiggerThanMobile = useMediaQuery("(min-width: 640px)")
  return isViewportBiggerThanMobile ? (
    <BookingModal>
      <button className={styles.button} onClick={onClick}>
        {children}
      </button>
    </BookingModal>
  ) : (
    <BookingDrawer>
      <button className={styles.button} onClick={onClick}>
        {children}
      </button>
    </BookingDrawer>
  )
}
