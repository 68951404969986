import { Meta, Owner, Pet } from "@/shared/types/global-store"
import { PricingResponse } from "@/shared/types/lead-api-types"
import { generateUserId } from "@/shared/utils/helpers"
import toast from "react-hot-toast"
import { createMachine } from "xstate"

import type * as T from "./helpers"
import {
  addAttributionQuestion,
  addNewPet,
  addOwnerAddress,
  addOwnerBirthdayAndBirthCity,
  addOwnerMailAndPhone,
  addOwnerName,
  addPetBirthDate,
  addPetBreed,
  addPetId,
  addPetName,
  addPetSex,
  addPetType,
  addPricingPlan,
  addPromoCode,
  addQuoteId,
  addStartDate,
  assignPrepackageVersionToB,
  choosePrepackage,
  clearDraftPets,
  customizeCoverage,
  deletePet,
  resetMachine,
  setLoadingPrepackage,
  setPrice,
  setStep,
  stopLoadingPrepackage,
  syncBackHelper as syncBack,
  updateMultiplePetsQuotes,
  updatePet,
  updatePricingPlan,
  updatePricingPlanSimple,
} from "./helpers"

export type Marketing = {
  referrer_details?: string
  referrer_type?: string
}

export type SubscriptionFlowContext = {
  pets: Pet[]
  currentPetId?: string
  owner: Owner
  meta: Meta
  pricing?: PricingResponse
  marketing: Marketing
  step: number
  setLoadingPrepackage: boolean
  prepackageVersion?: "A" | "B" | null
}

export type SubscriptionFlowEvents =
  | T.AddPetNameEvent
  | T.AddPetSexEvent
  | T.AddPetTypeEvent
  | T.AddPetBreedEvent
  | T.AddPetBirthDateEvent
  | T.AddPetIdEvent
  | T.AddNewPetEvent
  | T.UpdatePetEvent
  | T.DeletePetEvent
  | T.ValidateTribeEvent
  | T.AddOwnerNameEvent
  | T.AddOwnerMailAndPhoneEvent
  | T.CustomizeCoverageEvent
  | T.ChoosePrepackageEvent
  | T.AddPricingPlanEvent
  | T.UpdatePricingPlanEvent
  | T.ValidatePricingPlanEvent
  | T.AddQuoteIdEvent
  | T.ValidatePetPlansEvent
  | T.AddOwnerAddressEvent
  | T.AddOwnerBirthDateAndBirthCityEvent
  | T.ResetMachineEvent
  | T.GoToPriviousStateEvent
  | T.AddAttributionQuestionEvent
  | T.SetPriceEvent
  | T.SetStepEvent
  | T.StopLoadingPrepackageEvent
  | T.AddStartDateEvent
  | T.UpdatePricingPlanWithoutRedirectEvent
  | T.UpdateMultiplePetsQuotesEvent
  | {
      type:
        | "GO_TO_PET_NAME"
        | "GO_TO_PET_SEX"
        | "GO_TO_PET_BIRTHDATE"
        | "GO_TO_PET_TYPE"
        | "GO_TO_PET_BREED"
        | "GO_TO_TRIBE"
        | "GO_TO_OWNER_NAME"
        | "GO_TO_OWNER_MAIL_AND_PHONE"
        | "GO_TO_OWNER_ADDRESS"
        | "GO_TO_OWNER_BIRTHDATE"
        | "GO_TO_TRIBE_QUOTE"
        | "GO_TO_PREPACKAGES"
        | "GO_TO_COVERAGE"
        | "GO_TO_MODALITIES"
        | "GO_TO_PRICING"
        | "GO_TO_RECAP"
        | "GO_TO_ATTRIBUTION_QUESTION"
        | "VALIDATE_PET_BIRTHDATE"
        | "SET_STEP"
        | "ADD_START_DATE"
        | "UPDATE_MULTIPLE_PETS_QUOTES"
    }

const actions = {
  addNewPet,
  addOwnerMailAndPhone,
  addOwnerName,
  addPetBirthDate,
  addPetId,
  addPetName,
  addPetSex,
  addPetType,
  addPetBreed,
  addPricingPlan,
  customizeCoverage,
  deletePet,
  choosePrepackage,
  updatePet,
  updatePricingPlan,
  addQuoteId,
  addOwnerAddress,
  addOwnerBirthdayAndBirthCity,
  clearDraftPets,
  resetMachine,
  syncBack,
  addAttributionQuestion,
  setPrice,
  addPromoCode,
  setStep,
  setLoadingPrepackage,
  stopLoadingPrepackage,
  assignPrepackageVersionToB,
  addStartDate,
  updatePricingPlanSimple,
  updateMultiplePetsQuotes,
}

export const subscriptionFlowMachine = createMachine(
  {
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as SubscriptionFlowContext,
      events: {} as SubscriptionFlowEvents,
    },
    id: "subscription-flow",
    context: {
      pets: [],
      owner: {} as Owner,
      meta: {
        user_id: generateUserId(),
      } as Meta,
      marketing: {},
      step: 1,
      setLoadingPrepackage: false,
      prepackageVersion: null,
    },
    initial: "landing",
    states: {
      landing: {
        initial: "quoteCreation",
        states: {
          quoteCreation: {
            invoke: {
              id: "create-quote",
              src: "createQuote",
              onDone: {
                actions: "addQuoteId",
                target: "handleUrlParams",
              },
              onError: {
                actions: [() => toast.error("API Create Quote Error")],
                target: "handleUrlParams",
              },
            },
          },
          handleUrlParams: {
            invoke: {
              id: "handle-url-params",
              src: "urlParamsHandler",
              onDone: [
                {
                  target: "#subscription-flow.pricing.prepackages",
                  actions: ["assignPrepackageVersionToB", "setLoadingPrepackage"],
                  cond: "hasToRedirectToPrepackages",
                },
                {
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.petInformations.petName",
                  cond: "hasNoBreedAndNoPetNameFromLp",
                },
                {
                  target: "#subscription-flow.petInformations.petBreed",
                  cond: "hasNoBreedFromLp",
                },
                {
                  target: "#subscription-flow.pricing.pricing",
                  cond: "hastValidPets",
                },
                {
                  target: "#subscription-flow.petInformations",
                },
              ],
            },
          },
        },
      },
      petInformations: {
        initial: "petName",
        states: {
          petName: {
            entry: ["goToPetName", "addPromoCode"],
            on: {
              ADD_PET_NAME: {
                actions: ["addPetName", "goToPetSex"],
                target: "petSex",
              },
              PREVIOUS: {
                target: "tribe",
                cond: "hastValidPets",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petSex: {
            entry: "goToPetSex",
            on: {
              ADD_PET_SEX: {
                actions: "addPetSex",
                target: "petType",
              },
              PREVIOUS: {
                target: "petName",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petType: {
            entry: "goToPetType",
            on: {
              ADD_PET_TYPE: {
                actions: "addPetType",
                target: "petBreed",
              },
              PREVIOUS: {
                target: "petSex",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petBreed: {
            entry: "goToPetBreed",
            on: {
              ADD_PET_BREED: {
                actions: "addPetBreed",
                target: "petBirthDate",
              },
              PREVIOUS: {
                target: "petType",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petBirthDate: {
            entry: "goToPetBirthDate",
            on: {
              ADD_PET_BIRTH_DATE: {
                actions: ["addPetBirthDate", "addPetId"],
              },
              VALIDATE_PET_BIRTHDATE: [
                {
                  actions: ["syncBack", "assignPrepackageVersionToB"],
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasPrepackageVersionB",
                },
                {
                  target: "tribe",
                },
              ],
              PREVIOUS: {
                target: "petBreed",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          tribe: {
            entry: ["goToTribe", "clearDraftPets"],
            on: {
              ADD_NEW_PET: {
                actions: "addNewPet",
                target: "petName",
              },
              UPDATE_PET: {
                actions: "updatePet",
                target: "petName",
              },
              DELETE_PET: {
                actions: "deletePet",
                cond: "hasMultiplePets",
              },
              VALIDATE_TRIBE: [
                {
                  actions: ["syncBack", "assignPrepackageVersionToB"],
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasPrepackageVersionB",
                },
                {
                  target: "#subscription-flow.ownerInformations.attribitionQuestion",
                },
              ],
              PREVIOUS: [
                {
                  target: "#subscription-flow.pricing.prepackages",
                  cond: "hasPrepackageVersionB",
                },
                {
                  target: "petBirthDate",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      ownerInformations: {
        states: {
          attribitionQuestion: {
            entry: "goToAttribitionQuestion",
            on: {
              ADD_ATTRIBUTION_QUESTION: {
                actions: ["addAttributionQuestion", "syncBack"],
                target: "ownerMailAndPhone",
              },
              PREVIOUS: [
                {
                  actions: "syncBack",
                  target: "#subscription-flow.landing.handleUrlParams",
                  cond: "hasToRedirectToPrepackages",
                },
                {
                  target: "#subscription-flow.petInformations.tribe",
                },
              ],

              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerMailAndPhone: {
            entry: "goToOwnerMailAndPhone",
            on: {
              ADD_OWNER_MAIL_AND_PHONE: [
                {
                  actions: ["addOwnerMailAndPhone", "syncBack"],
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  actions: ["addOwnerMailAndPhone", "syncBack"],
                  target: "#subscription-flow.pricing.prepackages",
                },
              ],
              PREVIOUS: {
                target: "#subscription-flow.ownerInformations.attribitionQuestion",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      pricing: {
        states: {
          tribeQuote: {
            entry: "goToTribeQuote",
            on: {
              ADD_NEW_PET: {
                actions: "addNewPet",
                target: "#subscription-flow.petInformations.petName",
              },
              UPDATE_PET: {
                actions: "updatePet",
                target: "#subscription-flow.petInformations.petName",
              },
              DELETE_PET: {
                actions: "deletePet",
                cond: "hasMultiplePets",
              },
              CUSTOMIZE_COVERAGE: {
                actions: "customizeCoverage",
                target: "prepackages",
              },
              VALIDATE_PET_PLANS: {
                target: "#subscription-flow.ownerInformationsPart2.ownerName",
                cond: "isAllPetsPriced",
              },
              PREVIOUS: [
                {
                  target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
              UPDATE_MULTIPLE_PETS_QUOTES: {
                actions: "updateMultiplePetsQuotes",
              },
            },
          },
          prepackages: {
            entry: "goToPrepackages",
            on: {
              SET_PRICE: {
                actions: "setPrice",
              },
              ADD_START_DATE: {
                actions: "addStartDate",
              },
              UPDATE_PRICING_PLAN_WITHOUT_REDIRECT: {
                actions: "updatePricingPlanSimple",
              },
              ADD_PRICING_PLAN: [
                {
                  actions: "addPricingPlan",
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  actions: "addPricingPlan",
                  target: "#subscription-flow.petInformations.tribe",
                  cond: "hasPrepackageVersionB",
                },

                {
                  actions: "addPricingPlan",
                  target: "#subscription-flow.ownerInformationsPart2.ownerName",
                },
              ],
              UPDATE_MULTIPLE_PETS_QUOTES: {
                actions: "updateMultiplePetsQuotes",
              },
              CHOOSE_PREPACKAGE: [
                {
                  actions: "choosePrepackage",
                },
              ],
              STOP_LOADING_PREPACKAGE: {
                actions: "stopLoadingPrepackage",
              },
              CUSTOMIZE_COVERAGE: {
                actions: "customizeCoverage",
                target: "coverage",
              },
              PREVIOUS: [
                {
                  target: "tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.landing.handleUrlParams",
                  cond: "hasToRedirectToPrepackages",
                },
                {
                  target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          coverage: {
            entry: "goToCoverage",
            on: {
              SET_PRICE: {
                actions: "setPrice",
              },
              ADD_PRICING_PLAN: {
                actions: "addPricingPlan",
                target: "modalities",
              },
              PREVIOUS: [
                {
                  target: "tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "prepackages",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          modalities: {
            entry: "goToModalities",
            on: {
              ADD_START_DATE: {
                actions: "addStartDate",
              },
              SET_PRICE: {
                actions: "setPrice",
              },
              UPDATE_PRICING_PLAN: {
                actions: "updatePricingPlan",
              },
              GO_TO_PRICING: {
                target: "pricing",
              },
              PREVIOUS: {
                target: "coverage",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          pricing: {
            entry: ["goToPricing", "addPromoCode"],
            on: {
              SET_PRICE: {
                actions: "setPrice",
              },
              UPDATE_PRICING_PLAN_WITHOUT_REDIRECT: {
                actions: "updatePricingPlanSimple",
              },
              UPDATE_PRICING_PLAN: {
                actions: "updatePricingPlan",
              },
              VALIDATE_PRICING_PLAN: [
                {
                  target: "tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.petInformations.tribe",
                  cond: "hasPrepackageVersionB",
                },
                {
                  target: "#subscription-flow.ownerInformationsPart2.ownerName",
                },
              ],
              PREVIOUS: {
                target: "modalities",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      ownerInformationsPart2: {
        states: {
          ownerName: {
            entry: "goToOwnerInformations",
            on: {
              ADD_OWNER_NAME: {
                actions: ["addOwnerName", "syncBack"],
                target: "ownerAddress",
              },
              PREVIOUS: [
                {
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.pricing.prepackages",
                  cond: "choosePrepackage",
                },
                {
                  target: "#subscription-flow.pricing.pricing",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerAddress: {
            entry: "goToOwnerAddress",
            on: {
              ADD_OWNER_ADDRESS: {
                actions: ["addOwnerAddress", "syncBack"],
                target: "ownerBirthDate",
              },
              PREVIOUS: {
                target: "ownerName",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerBirthDate: {
            entry: "goToOwnerBirthDate",
            on: {
              ADD_OWNER_BIRTHDATE_AND_BIRTHCITY: {
                actions: ["addOwnerBirthdayAndBirthCity", "syncBack"],
                target: "#subscription-flow.recap",
              },
              PREVIOUS: {
                target: "ownerAddress",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      recap: {
        entry: "goToRecap",
        on: {
          UPDATE_PET: {
            actions: "updatePet",
            target: "petInformations.petName",
          },
          DELETE_PET: {
            actions: "deletePet",
            cond: "hasMultiplePets",
          },
          CUSTOMIZE_COVERAGE: {
            actions: "customizeCoverage",
            target: "pricing.prepackages",
          },
          PREVIOUS: {
            target: "#subscription-flow.ownerInformationsPart2.ownerBirthDate",
          },
          SET_STEP: {
            actions: "setStep",
          },
        },
      },
    },
    on: {
      // Event to trigger a redirect
      GO_TO_PET_NAME: {
        target: "#subscription-flow.petInformations.petName",
        cond: "hasQuoteId",
      },
      GO_TO_PET_SEX: {
        target: "#subscription-flow.petInformations.petSex",
      },
      GO_TO_PET_BIRTHDATE: {
        target: "#subscription-flow.petInformations.petBirthDate",
      },
      GO_TO_PET_TYPE: {
        target: "#subscription-flow.petInformations.petType",
      },
      GO_TO_PET_BREED: {
        target: "#subscription-flow.petInformations.petBreed",
      },
      GO_TO_TRIBE: {
        target: "#subscription-flow.petInformations.tribe",
      },
      GO_TO_OWNER_NAME: {
        target: "#subscription-flow.ownerInformationsPart2.ownerName",
      },
      GO_TO_OWNER_MAIL_AND_PHONE: {
        target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
      },
      GO_TO_OWNER_ADDRESS: {
        target: "#subscription-flow.ownerInformationsPart2.ownerAddress",
      },
      GO_TO_OWNER_BIRTHDATE: {
        target: "#subscription-flow.ownerInformationsPart2.ownerBirthDate",
      },
      GO_TO_TRIBE_QUOTE: {
        target: "#subscription-flow.pricing.tribeQuote",
      },
      GO_TO_PREPACKAGES: {
        target: "#subscription-flow.pricing.prepackages",
      },
      GO_TO_COVERAGE: {
        target: "#subscription-flow.pricing.coverage",
      },
      GO_TO_MODALITIES: {
        target: "#subscription-flow.pricing.modalities",
      },
      GO_TO_PRICING: {
        target: "#subscription-flow.pricing.pricing",
      },
      GO_TO_RECAP: {
        target: "#subscription-flow.recap",
      },
      GO_TO_ATTRIBUTION_QUESTION: {
        target: "#subscription-flow.ownerInformations.attribitionQuestion",
      },
      // Global events
      RESET_MACHINE: {
        actions: "resetMachine",
        target: "#subscription-flow.petInformations.petName",
      },
    },
  },
  {
    // @ts-expect-error this is temporary until we found what's wrong with this types
    actions,
  }
)
